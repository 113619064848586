.required-field::after {
  content: "*";
  color: red;
}

.good-color-style:focus {
  /* when the button is toggled off 
it is still in focus and a violet border will appear */
  border-color: rgb(52, 195, 143) !important;
/* box shadow is blue by default
but we do not want any shadow hence we have set 
all the values as 0 */
  box-shadow:
        0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.good-color-style:checked {
  background-color: #28a745 !important;
    border-color: #28a745 !important;
}

.warning-color-style:focus {
  border-color: rgb(241, 180, 76) !important;
  box-shadow:
        0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.warning-color-style:checked {
  background-color: rgb(241, 180, 76) !important;
    border-color: rgb(241, 180, 76) !important;
}
.danger-color-style:focus {
  border-color: rgb(244, 106, 106) !important;

  box-shadow:
        0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.danger-color-style:checked {
  background-color: rgb(244, 106, 106) !important;
    border-color: rgb(244, 106, 106) !important;
}


.user-guide-color-style:focus {
  /* when the button is toggled off 
it is still in focus and a violet border will appear */
  border-color: rgb(222, 49, 99) !important;
/* box shadow is blue by default
but we do not want any shadow hence we have set 
all the values as 0 */
  box-shadow:
        0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.user-guide-color-style:checked {
  background-color: #DE3163 !important;
    border-color: #DE3163 !important;
}


.row-fields-modal {
  /* display: flex; */
  display: grid;
  width: 100%;
}

.col-fields-modal {
  display: grid;
  flex: 1;
}

.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}

.strikeout td:after {
  content: "\00B7";
  font-size: 1px;
}